import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../../shared/common.styles'

export const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),

  container: {
    marginTop: 15
  },

  inputError: {
    borderColor: theme.error,

    '&:focus': {
      borderColor: theme.error
    }
  },

  button: {
    width: '100%',
    marginTop: 10
  },

  input: {
    display: 'block',
    width: '100%',
    fontSize: 15,
    lineHeight: 1.07,
    padding: 12,
    color: theme.white,
    borderRadius: 4,
    background: theme.field,
    outline: 'none',
    border: '1px solid transparent',
    transition: 'all 0.125s',

    '&:focus': {
      background: theme.fieldHighlight,
      border: '1px solid #979797',
      boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.35), 0 2px 4px 0 rgba(0, 0, 0, 0.3), inset 0 1px 0 0 rgba(0, 0, 0, 0.5)'
    }
  },

  fieldError: {
    visibility: 'hidden',
    display: 'inline-block',
    color: theme.error,
    fontSize: 10,
    margin: [2, 0, 0]
  },

  visible: {
    visibility: 'visible'
  },
  chargeInfo: {
    fontSize: 14
  },
  inputTitle: {
    fontSize: 14,
    marginBottom: 5
  }
}))
