import React from 'react'

const RadioDotChecked = props => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        style={{ fill: '#cafd34' }}
        d="M10,2a8,8,0,1,0,8,8A8,8,0,0,0,10,2Z"
        transform="translate(-2 -2)"
      />
      <path
        style={{ fill: '#191919' }}
        d="M5,2A3,3,0,1,0,8,5,3,3,0,0,0,5,2Z"
        transform="translate(3 3)"
      />
    </svg>
  )
}

export default RadioDotChecked
