import { createUseStyles } from 'react-jss'
import { commonStyles } from '../common.styles'

export const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),

  priceChangeAlertContainer: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.5,
    textAlign: 'center',
    marginBottom: 20,
    color: theme.neonGreen,
    [theme.media.mobile]: {
      padding: [0, 10]
    },
    [theme.media.smallMobile]: {
      padding: [0, 10]
    }
  }
}))
