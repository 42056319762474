import React from 'react'
import PropTypes from 'prop-types'

import useChangeSubscriptionScreen from './hooks/use-change-subscription-screen'
import { LoadingModal } from '../../components/loading/modal'
import { direction } from './constants'

const DowngradeSubscriptionModal = ({ modalCloseTarget }) => {
  const { screen } = useChangeSubscriptionScreen(
    direction.downgrade,
    modalCloseTarget
  )
  return screen || <LoadingModal />
}

DowngradeSubscriptionModal.propTypes = {
  modalCloseTarget: PropTypes.string
}

export default DowngradeSubscriptionModal
