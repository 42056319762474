import React from 'react'
import PropTypes from 'prop-types'
import useStyles from '../styles'

const Error = ({ error, onStartOver }) => {
  const { errorCode, message } = error
  const styles = useStyles()
  return (
    <div>
      <h1>Error!</h1>
      <div>
        Error Code:
        {errorCode}
      </div>
      <div>
        Error Message:
        {message}
      </div>
      <div className={styles.primaryButton} onClick={onStartOver}>
        Close and Try again
      </div>
    </div>
  )
}

Error.propTypes = {
  error: PropTypes.shape({
    errorCode: PropTypes.string,
    message: PropTypes.string
  }).isRequired,
  onStartOver: PropTypes.func.isRequired
}
export default Error
