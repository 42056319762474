import { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { path } from 'ramda'
import { useLazyQuery } from '@apollo/react-hooks'

import { creditCardStatus, modalContent } from '../../shared/subscription-constants'
import { getGQLErrorCode } from '../../../lib/apollo'
import { getModalLocation } from '../../../lib/modal'
import { MODALS } from '../../../constants'
import ACCOUNT_CREDIT_CARDS from '../../../../graphql/queries/account-credit-cards.gql'

/**
 * Hook to get all valida credit cards
 * @returns {{validCreditCards: *[], loading: boolean}}
 */
export const useCreditCards = () => {
  const location = useLocation()
  const history = useHistory()

  const [validCreditCards, setValidCreditCards] = useState([])

  const [fetchCards, { loading }] = useLazyQuery(ACCOUNT_CREDIT_CARDS, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const cardsList = path(['account', 'creditcards'], data)
      const activeCreditCards = cardsList && cardsList.filter(card => card.status === creditCardStatus.ACTIVE)
      setValidCreditCards(activeCreditCards)
    },
    onError: error => {
      const errorContent = getModalLocation(location, MODALS.qsParams.addSubscription, {
        content: modalContent.error,
        error: getGQLErrorCode(error)
      })
      history.push(errorContent)
    }
  })

  useEffect(() => {
    fetchCards()
  }, [])

  return {
    validCreditCards,
    loading
  }
}
