import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useStyles } from './payment-password-form.styles'

const PaymentPasswordForm = ({
  error,
  buttonOnClick,
  buttonDisabled,
  chargeInfo,
  buttonLabel = 'Complete Payment',
  ...passwordInput
}) => {
  const styles = useStyles()
  return (
    <form className={styles.container}>
      <div className={styles.inputTitle}>Enter your NEON password</div>
      <input
        name="password"
        type="password"
        placeholder="Neon Password"
        className={classNames(styles.input, {
          [styles.inputError]: Boolean(error)
        })}
        {...passwordInput}
      />
      <p
        className={classNames(styles.fieldError, {
          [styles.visible]: Boolean(error)
        })}
      >
        {error}
      </p>
      <div className={styles.chargeInfo}>{chargeInfo}</div>
      <button
        onClick={buttonOnClick}
        className={classNames(styles.primaryButton, styles.button)}
        disabled={buttonDisabled}
      >
        {buttonLabel}
      </button>
    </form>
  )
}

PaymentPasswordForm.propTypes = {
  error: PropTypes.string,
  buttonOnClick: PropTypes.func,
  buttonDisabled: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string,
  chargeInfo: PropTypes.string
}

export default PaymentPasswordForm
