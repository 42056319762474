import React from 'react'

const RadioDot = props => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        style={{ fill: '#fff' }}
        d="M10,2a8,8,0,1,0,8,8A8,8,0,0,0,10,2Z"
        transform="translate(-2 -2)"
      />
    </svg>
  )
}

export default RadioDot
