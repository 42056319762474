import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles(theme => ({
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  cardIcon: {
    width: 30,
    height: 20,
    marginRight: 6
  },

  icon: {
    width: 13,
    height: 13,
    marginRight: 10
  },

  dotGroups: {
    display: 'flex',
    alignItems: 'center'
  },

  dot: {
    height: 5,
    width: 5,
    borderRadius: '50%',
    backgroundColor: theme.white,
    marginRight: 5
  },

  endNumber: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    letterSpacing: 3
  },

  name: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    cursor: 'pointer'
  },

  card: {
    display: 'flex',
    alignItems: 'center'
  }

}))
