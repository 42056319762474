import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'query-string'
import { useLazyQuery, useMutation } from 'react-apollo'
import { useDispatch } from 'react-redux'

import {
  browserTimeZone,
  direction,
  errors,
  paymentLabel
} from '../constants'
import { useCreditCards } from '../../add-subscription-modal/hooks/use-credit-cards'
import { useAddPaymentMethodIframeWithOrderId } from '../../shared/hooks/use-add-payment-method-iframe'
import SUBSCRIPTION_DATA_FULL from '../graphql/subscription-data-full.gql'
import CHANGE_SUBSCRIPTION_PAYMENT_VIEW from '../graphql/change-subscription-payment-view.gql'
import CHANGE_SUBSCRIPTION from '../graphql/change-subscription.gql'
import { formatTime } from '../../shared/helper'
import {
  endLoadingOverlay,
  enableLoadingOverlay
} from '../../../actions'
import { segmentTrackChangeSubscription, segmentTrackInitiatePurchase, segmentTrackCompletePurchase } from '../../../segment/segment-track'

/**
 * Hook to manage upgrade/downgrade grapqhl queries
 * Return credit cards and iframe
 * @param {*} changeDirection upgrade or downgrade
 * @param {*} product
 * @returns
 */
const useChangeSubscription = (changeDirection, product) => {
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [resultIframe, setResultIframe] = useState()
  const [iframeUrl, setiframeUrl] = useState()
  const [firstPayment, setFirstPayment] = useState()
  const [priceChargedNowDate, setPriceChargedNowDate] = useState()
  const [priceChargedNow, setPriceChargedNow] = useState()
  const history = useHistory()
  const location = useLocation()
  const { validCreditCards, loading: creditCardLoading } = useCreditCards()
  const dispatch = useDispatch()
  const loadingOverlayKey = 'paymentProcessing'
  const { sku } = product

  // Manage iframe ref and messages
  const { iframeRef } = useAddPaymentMethodIframeWithOrderId(
    (status, orderId) => {
      if (status === 'success') {
        setResultIframe(orderId)
      }
    },
    () => {
      history.push({
        ...location,
        search: qs.stringify({
          ...qs.parse(location.search),
          errorCode: errors.iframePaymentAttemptFailed.errorCode
        })
      })
    }
  )

  // Perform changeSubscription in middleware
  const [
    changeSubscription,
    { data: resultMutation, loading: loadingMutation, error: errorMutation }
  ] = useMutation(CHANGE_SUBSCRIPTION, {
    variables: { sku, direction: changeDirection }
  })

  // Validate account status in middleware. Returns iframeurl and payment date
  const [
    getChangeSubscriptionPaymentView,
    {
      data: validateResponse,
      loading: validateLoading,
      error: validateError,
      called: validateCalled
    }
  ] = useLazyQuery(CHANGE_SUBSCRIPTION_PAYMENT_VIEW, {
    variables: {
      sku,
      direction: changeDirection
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  // Validate upgrade in middleware and get the iframeurl
  useEffect(() => {
    if (!validateCalled) {
      getChangeSubscriptionPaymentView()
    } else if (validateCalled && !validateLoading && validateResponse) {
      const {
        changeSubscriptionPaymentView: {
          iframeUrl: iframe,
          firstPayment: paymentDate,
          priceChargedNowDate: chargedNowDate,
          priceChargedNow: chargedNowPrice
        }
      } = validateResponse
      const firstPaymentDate = paymentDate && formatTime(paymentDate, browserTimeZone)
      const formattedChargedNowDate = chargedNowDate && formatTime(chargedNowDate, browserTimeZone)
      setiframeUrl(iframe)
      setFirstPayment(firstPaymentDate)
      setPriceChargedNowDate(formattedChargedNowDate)
      setPriceChargedNow(chargedNowPrice)

      // Send segment track event when payment is available
      const paymentDue = formattedChargedNowDate || firstPaymentDate
      const paymentIndex = product.details.findIndex(e => e.name === paymentLabel)
      const payment = chargedNowPrice || Number(product.details[paymentIndex]?.value?.replace('$', ''))
      segmentTrackInitiatePurchase({ ...product, paymentDue, payment }, null)
    }
  }, [validateLoading, validateResponse])

  /**
   * Get Subscription info to update my account
   * We use network-only so the result will be stored in apollo cache.
   * Apollo cache update will trigger a new render to get new plan information
   */
  const [
    getActiveSubscriptions,
    {
      called: getActiveSubsCalled,
      data: getActiveSubsData,
      error: getActiveSubsError,
      loading: getActiveSubLoading
    }
  ] = useLazyQuery(SUBSCRIPTION_DATA_FULL, {
    fetchPolicy: 'network-only'
  })

  // If upgrade was success, update my account and redirect to confirm
  useEffect(() => {
    if (!getActiveSubsCalled && (resultMutation || resultIframe)) {
      getActiveSubscriptions()
    } else if (
      getActiveSubsCalled &&
      (getActiveSubsData || getActiveSubsError)
    ) {
      setProcessing(false)
      let orderId = 0
      if (getActiveSubsData && resultMutation) {
        orderId = resultMutation.changeSubscription
      } else if (getActiveSubsData && resultIframe) {
        orderId = resultIframe
      }
      history.push({
        ...location,
        search: qs.stringify({
          ...qs.parse(location.search),
          orderId
        })
      })
      dispatch(endLoadingOverlay(loadingOverlayKey, 'paymentProcessing'))

      // Add segment data analytics for changing subscription
      const {
        name
      } = getActiveSubsData?.account?.subscription?.upcomingSubscription || getActiveSubsData?.account?.subscription?.currentSubscription
      segmentTrackChangeSubscription({
        name,
        sku
      })

      // Add segment data analytics for completing purchase
      segmentTrackCompletePurchase({
        planTitle: name,
        planSku: sku,
        paymentDue: priceChargedNowDate || firstPayment,
        payment: Number(priceChargedNow),
        orderId
      })
    }
  }, [
    resultMutation,
    getActiveSubsCalled,
    getActiveSubsData,
    getActiveSubsError,
    resultIframe
  ])

  // Loading= dependencies needed to start change subscription
  // Processing= peforming actual upgrade/downgrade
  useEffect(() => {
    setLoading(validateLoading || creditCardLoading)
    if (loadingMutation || getActiveSubLoading) {
      setProcessing(true)
    }
  }, [
    validateLoading,
    creditCardLoading,
    loadingMutation,
    getActiveSubLoading
  ])

  // Catch errors and redirect to error screen
  useEffect(() => {
    const changeToError = error => {
      history.push({
        ...location,
        search: qs.stringify({
          ...qs.parse(location.search),
          errorCode: error
        })
      })
    }
    if (errorMutation && changeDirection === direction.upgrade) {
      changeToError(errors.mutationUpgrade.errorCode)
    } else if (errorMutation && changeDirection === direction.downgrade) {
      changeToError(errors.mutationDowngrade.errorCode)
    } else if (changeDirection === direction.upgrade && validateError) {
      changeToError(errors.upgradeNotAllowed.errorCode)
    } else if (changeDirection === direction.downgrade && validateError) {
      changeToError(errors.downgradeNotAllowed.errorCode)
    }

    if (errorMutation) {
      dispatch(endLoadingOverlay(loadingOverlayKey, 'paymentProcessing', { error: true }))
    }
  }, [errorMutation, validateError])

  // Prevent closing modal if processing
  useEffect(() => {
    if (processing) {
      dispatch(enableLoadingOverlay(loadingOverlayKey, 'paymentProcessing'))
    }
  }, [
    processing
  ])

  return {
    priceChargedNowDate,
    priceChargedNow,
    firstPayment,
    iframeUrl,
    iframeRef,
    loading,
    processing,
    changeSubscription,
    validCreditCards
  }
}

export default useChangeSubscription
