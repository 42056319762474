import React, { useState } from 'react'
import PropTypes from 'prop-types'

import CreditCardItem, { CreditCardType } from './item/credit-card-item'
import PaymentPasswordForm from '../../add-subscription-modal/components/payment-password-form/payment-password-form'
import { useStyles } from './credit-card-picker.styles'

/**
 * A UI component render a list of credit cards with its password validation
 * @param className
 * @param validCreditCards
 * @param onSelected
 * @param defaultCardId
 */
const CreditCardPicker = ({
  className,
  validCreditCards,
  onSelected,
  defaultCardId,
  ...passwordValidation
}) => {
  const [selectedCardId, setSelectedCardId] = useState(defaultCardId)
  const selectHandler = paymentId => {
    onSelected(paymentId)
    setSelectedCardId(paymentId)
  }

  const style = useStyles()

  return (
    <div className={className}>
      {validCreditCards.map(card => (
        <div key={card.id} className={style.pickerItem}>
          <CreditCardItem
            isSelected={card.id === selectedCardId}
            onSelect={paymentId => selectHandler(paymentId)}
            card={card}
          />
          {card.id === selectedCardId && (
            <PaymentPasswordForm {...passwordValidation} />
          )}
        </div>
      ))}
    </div>
  )
}

CreditCardPicker.propTypes = {
  className: PropTypes.string,
  validCreditCards: PropTypes.arrayOf(CreditCardType).isRequired,
  onSelected: PropTypes.func,
  defaultCardId: PropTypes.string
}

export default CreditCardPicker
