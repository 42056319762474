import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles(theme => ({
  detailCard: {
    borderRadius: 4,
    padding: [18, 10],
    margin: [0, 0, 30],
    display: 'flex',
    width: '100%',
    maxWidth: 580,
    justifyContent: 'center',
    gap: '30px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    backgroundImage: 'linear-gradient(to bottom, #363636, #252525)',

    [theme.media.tablet]: {
      width: '100%',
      padding: [18, 100],
      margin: [10, 0, 30]
    }
  },

  detailList: {
    listStyle: 'none',
    width: '50%',
    margin: 0,
    padding: 0
  },

  detailItem: {
    margin: 0,
    padding: 0,
    lineHeight: 1.5
  },

  bold: {
    fontWeight: 800
  },

  green: {
    color: theme.neonGreen
  },

  alignRight: {
    textAlign: 'right'
  },

  alignLeft: {
    textAlign: 'left'
  }
}))
