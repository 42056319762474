import React from 'react'
import PropTypes from 'prop-types'

import RadioDotChecked from '../../../components/icons/radio-dot-checked'
import RadioDot from '../../../components/icons/radio-dot'
import useStyles from '../styles'

const DotIndicator = ({ isSelected }) => {
  const styles = useStyles()
  return isSelected ? (
    <RadioDotChecked className={styles.icon} />
  ) : (
    <RadioDot className={styles.icon} />
  )
}

DotIndicator.propTypes = {
  isSelected: PropTypes.bool.isRequired
}

export default DotIndicator
