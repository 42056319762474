
import { MANAGE_PLAN_OPTIONS } from '../constants'
/**
 * Hook to get basic profile limitation
 */
export const useOneWaySubscriptionChange = (manageSubsOptions) => {
  const downgradeAllowed = manageSubsOptions.includes(MANAGE_PLAN_OPTIONS.DOWNGRADE)
  const upgradeAllowed = manageSubsOptions.includes(MANAGE_PLAN_OPTIONS.UPGRADE)

  if (downgradeAllowed && !upgradeAllowed) {
    return MANAGE_PLAN_OPTIONS.DOWNGRADE
  }

  if (!downgradeAllowed && upgradeAllowed) {
    return MANAGE_PLAN_OPTIONS.UPGRADE
  }

  return null
}
