import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import CreditCardPicker from '../../shared/credit-card-picker/credit-card-picker'
import { CreditCardType } from '../../shared/credit-card-picker/item/credit-card-item'
import useStyles from '../styles'
import DotIndicator from './dot-indicator'

const CreditCardChangeSubscription = ({
  isSelected,
  onRadioClick,
  validCreditCards,
  clearInput,
  selectedCardPaymentId,
  setSelectedCardPaymentId,
  ...passwordProperties
}) => {
  const styles = useStyles()

  const creditCardsPickerHandler = paymentId => {
    setSelectedCardPaymentId(paymentId)
    clearInput()
  }

  // Select first credit card from the list
  useEffect(() => {
    if (validCreditCards.length > 0) {
      setSelectedCardPaymentId(validCreditCards[0].id)
    }
  }, [validCreditCards])

  return (
    <div className={styles.border}>
      <div className={styles.selector} onClick={onRadioClick}>
        <DotIndicator isSelected={isSelected} />
        Use an existing credit card
      </div>
      <div className={styles.divider} />
      {isSelected && (
        <CreditCardPicker
          validCreditCards={validCreditCards}
          defaultCardId={selectedCardPaymentId || validCreditCards[0].id}
          onSelected={paymentId => creditCardsPickerHandler(paymentId)}
          {...passwordProperties}
        />
      )}
    </div>
  )
}

CreditCardChangeSubscription.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onRadioClick: PropTypes.func.isRequired,
  clearInput: PropTypes.func.isRequired,
  validCreditCards: PropTypes.arrayOf(CreditCardType).isRequired,
  selectedCardPaymentId: PropTypes.string,
  setSelectedCardPaymentId: PropTypes.func.isRequired
}

export default CreditCardChangeSubscription
