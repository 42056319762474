import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { paymentLabel, direction, productsForAvod } from '../constants'
import useStyles from '../styles'
import useChangeSubscription from '../hooks/use-change-subscription'
import usePassword from '../hooks/use-password'
import PaymentMethodHeader from '../components/payment-method-header'
import { LoadingComponent } from '../../../components/loading'
import CreditCardChangeSubscription from '../components/credit-card-change-subscription'
import IframeChangeSubscription from '../components/iframe-change-subscription'
import { planType } from '../../shared/subscription-constants'

const Payment = ({ product, changeDirection }) => {
  const styles = useStyles()
  const [isSelectedCardPicker, setIsSelectedCardPicker] = useState(true)
  const [selectedCardPaymentId, setSelectedCardPaymentId] = useState()

  const {
    priceChargedNowDate,
    priceChargedNow,
    firstPayment,
    iframeUrl,
    iframeRef,
    loading: changeSubscriptionLoading,
    processing,
    changeSubscription,
    validCreditCards
  } = useChangeSubscription(changeDirection, product)

  const {
    onChange,
    onBlur,
    value,
    clear,
    onClick,
    enabled,
    error: passwordError,
    loading: passwordLoading
  } = usePassword(changeSubscription, selectedCardPaymentId)

  // Add payment date to details at specific position
  // Replace the payment date with today received from the server when the user is upgrading
  const paymentDetail = {
    name: 'Payment due',
    value: priceChargedNowDate ? `${priceChargedNowDate}` : `${firstPayment}`
  }
  const details = [...product.details]
  details.splice(1, 0, paymentDetail)
  // Replace the payment with the prorate amount when the user is upgrading
  if (priceChargedNow) {
    const paymentIndex = details.findIndex(e => e.name === paymentLabel)
    details[paymentIndex].value = `$${priceChargedNow}`
  }

  const pickerAndIframe =
    !(processing || passwordLoading) &&
    validCreditCards.length > 0 &&
    !!iframeRef &&
    !!iframeUrl

  const iframeOnly =
    !(processing || passwordLoading) &&
    validCreditCards.length === 0 &&
    !!iframeRef &&
    !!iframeUrl

  const isUpgradingPlan = changeDirection === direction.upgrade
  const isAnnuallyPlan = product?.type === planType.ANNUAL

  return changeSubscriptionLoading ? (
    <div className={styles.container}>
      <LoadingComponent pastDelay />
    </div>
  ) : (
    <div className={styles.container}>
      <PaymentMethodHeader
        label={product.label}
        detail={details}
        isStandardPlan={product.sku === productsForAvod.standardForAvod.sku}
        copy={product.copy}
        isUpgradingPlan={isUpgradingPlan}
        isAnnuallyPlan={isAnnuallyPlan}
      />
      {(processing || passwordLoading) && <LoadingComponent pastDelay />}

      {pickerAndIframe && (
        <>
          <CreditCardChangeSubscription
            isSelected={isSelectedCardPicker}
            onRadioClick={() => setIsSelectedCardPicker(true)}
            setSelectedCardPaymentId={setSelectedCardPaymentId}
            selectedCardPaymentId={selectedCardPaymentId}
            validCreditCards={validCreditCards}
            clearInput={clear}
            error={passwordError}
            buttonOnClick={onClick}
            buttonDisabled={!enabled}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            buttonLabel="Confirm"
          />
          <IframeChangeSubscription
            useHeader
            iframeRef={iframeRef}
            iframeUrl={iframeUrl}
            isSelected={!isSelectedCardPicker}
            onRadioClick={() => setIsSelectedCardPicker(false)}
          />
        </>
      )}

      {iframeOnly && (
        <IframeChangeSubscription
          useHeader={false}
          iframeRef={iframeRef}
          iframeUrl={iframeUrl}
        />
      )}
    </div>
  )
}

Payment.propTypes = {
  product: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    details: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    ),
    type: PropTypes.string,
    copy: PropTypes.string.isRequired
  }).isRequired,
  changeDirection: PropTypes.string.isRequired
}

export default Payment
