import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import useStyles from '../styles'

import DotIndicator from './dot-indicator'

const IframeChangeSubscription = ({
  useHeader,
  iframeRef,
  iframeUrl,
  isSelected,
  onRadioClick
}) => {
  const styles = useStyles()

  return useHeader ? (
    <div className={classNames(styles.border, styles.paymentContainer)}>
      <div className={styles.selector} onClick={onRadioClick} data-e2e="user-select-different-card-for-payment">
        <DotIndicator isSelected={isSelected} />
        Use a different card
      </div>
      {isSelected && (
        <iframe
          ref={iframeRef}
          src={iframeUrl}
          className={classNames(styles.iframe)}
          data-e2e="user-change-subscription-iframe"
        />
      )}
    </div>
  ) : (
    <iframe
      ref={iframeRef}
      src={iframeUrl}
      className={classNames(styles.iframe)}
    />
  )
}

IframeChangeSubscription.propTypes = {
  useHeader: PropTypes.bool.isRequired,
  iframeRef: PropTypes.func.isRequired,
  iframeUrl: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onRadioClick: PropTypes.func
}

export default IframeChangeSubscription
