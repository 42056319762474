import { EV_SUBSCRIPTION_STATUS } from '../../../constants'

const downgradeAllowedSkus = ['NEONPRP12']
/**
 * Only allow downgrade if current subscription is Annual and is active
 */
const isDowngradeAllowed = (
  evSubscriptionStatus,
  evUpcomingSubscriptionStatus,
  sku
) => {
  return (
    evSubscriptionStatus === EV_SUBSCRIPTION_STATUS.ACTIVE &&
    !evUpcomingSubscriptionStatus &&
    sku &&
    downgradeAllowedSkus.indexOf(sku) >= 0
  )
}

export default isDowngradeAllowed
