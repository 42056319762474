import React from 'react'

const MasterCardIcon = props => {
  return (
    <svg viewBox="0 0 34.95 23.24" {...props}>
      <path
        style={{ fill: '#0c2d5b' }}
        d="M0,23.24H34.95V0H0Z"
      />
      <path
        style={{ fill: '#f1ab3d' }}
        d="M3.148,16.437a9.577,9.577,0,0,0,15.966-7,9.577,9.577,0,0,0-15.966-7,9.35,9.35,0,0,0,0,13.994"
        transform="translate(14.027 1.8)"
      />
      <path
        style={{ fill: '#f1ab3d' }}
        d="M.319.611A.313.313,0,0,1,0,.3.3.3,0,0,1,.319,0a.3.3,0,0,1,.3.3A.312.312,0,0,1,.319.611Zm0-.537A.231.231,0,0,0,.075.3.245.245,0,0,0,.319.537.242.242,0,0,0,.544.3.227.227,0,0,0,.319.074ZM.263.444H.206V.167H.319A.114.114,0,0,1,.394.185h0C.413.2.431.223.431.241.431.276.414.315.375.315L.431.444H.356L.319.333H.263V.444Zm0-.222V.278H.337C.356.278.356.259.356.241A.018.018,0,0,0,.337.222H.263Z"
        transform="translate(31.867 16.201)"
      />
      <path
        style={{ fill: '#d82332' }}
        d="M19.058,8.441a9.3,9.3,0,0,0-.169-1H13.043a10.047,10.047,0,0,1,.281-1h5.285q-.169-.5-.394-1h-4.5q.253-.5.562-1h3.373a6.2,6.2,0,0,0-.731-1H15.011a9.645,9.645,0,0,1,.956-1A9.577,9.577,0,0,0,0,9.44a9.492,9.492,0,0,0,9.557,9.44,9.547,9.547,0,0,0,6.409-2.443,12.339,12.339,0,0,0,.974-1h-1.93a9.841,9.841,0,0,1-.731-1h3.373a8.645,8.645,0,0,0,.562-1h-4.5a7.928,7.928,0,0,1-.394-1h5.285a9.938,9.938,0,0,0,.281-1,9.307,9.307,0,0,0,.169-1,8.906,8.906,0,0,0,0-2"
        transform="translate(1.209 1.8)"
      />
      <path
        style={{ fill: '#fffffe' }}
        d="M.319.611A.313.313,0,0,1,0,.3.3.3,0,0,1,.319,0a.3.3,0,0,1,.3.3A.312.312,0,0,1,.319.611Zm0-.537A.231.231,0,0,0,.075.3.245.245,0,0,0,.319.537.242.242,0,0,0,.544.3.227.227,0,0,0,.319.074ZM.263.444H.206V.167H.319A.114.114,0,0,1,.394.185h0C.413.2.431.223.431.241.431.276.414.315.375.315L.431.444H.356L.319.333H.263V.444Zm0-.222V.278H.337C.356.278.356.259.356.241A.018.018,0,0,0,.337.222H.263Z"
        transform="translate(31.867 13.129)"
      />
      <path
        style={{ fill: '#0c2d5b' }}
        d="M1.574,4.424a2.678,2.678,0,0,1-.787.129A.747.747,0,0,1,0,3.7,2.506,2.506,0,0,1,.037,3.35l.056-.37L.15,2.665.6,0h.993L1.48.592H2.1l-.15.981H1.33L1.049,3.165c0,.055-.019.111-.019.148,0,.2.112.3.356.3a1.672,1.672,0,0,0,.319-.037l-.131.852"
        transform="translate(12.359 9.538)"
      />
      <path
        style={{ fill: '#0c2d5b' }}
        d="M1.761,4.072a1.8,1.8,0,0,1-1.3-.44A1.732,1.732,0,0,1,0,2.332,2.72,2.72,0,0,1,.515.666,1.633,1.633,0,0,1,1.818,0a1.368,1.368,0,0,1,1.01.386A1.45,1.45,0,0,1,3.2,1.425a4.14,4.14,0,0,1-.131.963H1.012V2.5c0,.461.294.685.9.685A2.563,2.563,0,0,0,3,2.943l-.169.962A3.589,3.589,0,0,1,1.761,4.072ZM1.724.907c-.335,0-.561.237-.656.685H2.211V1.407A.454.454,0,0,0,1.724.907Z"
        transform="translate(14.308 10.019)"
      />
      <path
        style={{ fill: '#0c2d5b' }}
        d="M4.179,4.794H3.148l.6-3.683L2.417,4.794H1.705L1.63,1.129.993,4.794H0L.806,0h1.48l.056,2.962L3.336,0H4.985L4.179,4.794"
        transform="translate(1.827 9.223)"
      />
      <path
        style={{ fill: '#0c2d5b' }}
        d="M.918,4.054a.855.855,0,0,1-.649-.3A1.165,1.165,0,0,1,0,2.98a2.011,2.011,0,0,1,.075-.5c.17-.617.8-.981,1.724-1a4,4,0,0,1,.45.018.764.764,0,0,0,.037-.259c0-.246-.181-.333-.693-.333a4.077,4.077,0,0,0-.862.111L.6,1.073H.525L.675.2a3.872,3.872,0,0,1,1.2-.2A1.565,1.565,0,0,1,2.9.289a1.06,1.06,0,0,1,.347.858c0,.03,0,.059,0,.09a2.981,2.981,0,0,1-.057.669L2.98,3.295l-.037.241-.038.2v.13l-.019.111h-.9l.019-.407A1.177,1.177,0,0,1,.918,4.054Zm.937-1.833c-.568,0-.881.21-.881.592a.364.364,0,0,0,.375.407.6.6,0,0,0,.539-.275,1.4,1.4,0,0,0,.173-.706.663.663,0,0,1-.126-.011A.438.438,0,0,0,1.855,2.221Z"
        transform="translate(6.438 10.037)"
      />
      <path
        style={{ fill: '#0c2d5b' }}
        d="M4.647,1.037a1.991,1.991,0,0,1,.918.259L5.734.241C5.641.2,5.622.2,5.491.148L5.06.056A1.7,1.7,0,0,0,4.591,0,1.888,1.888,0,0,0,3.467.2a2.512,2.512,0,0,0-.618.5L2.736.666l-1.068.74L1.724,1H.618L0,4.887H1.031L1.406,2.8a3.477,3.477,0,0,1,.225-.389.584.584,0,0,1,.562-.241h.075c-.019.222-.037.481-.037.74A1.841,1.841,0,0,0,4.085,4.979a2.93,2.93,0,0,0,.9-.148l.188-1.092a1.981,1.981,0,0,1-.881.241c-.6,0-.956-.426-.956-1.166,0-1.037.525-1.777,1.312-1.777"
        transform="translate(17.475 9.13)"
      />
      <path
        style={{ fill: '#0c2d5b' }}
        d="M3.261,4.85A1.135,1.135,0,0,1,2.342,4.4a1.885,1.885,0,0,1-.356-1.182,3.581,3.581,0,0,1,.187-1.148h0a.577.577,0,0,0-.449.24c-.074.091-.335.386-.337.389L1.049,4.794H0L.637.907H1.743l-.056.407.656.389A1.563,1.563,0,0,1,3.71.833a1.055,1.055,0,0,1,.937.537L4.872,0H5.94L5.153,4.794H4.16l.056-.389A1.2,1.2,0,0,1,3.261,4.85ZM3.9,1.8a.678.678,0,0,0-.586.373,1.865,1.865,0,0,0-.22.942c0,.518.167.759.525.759.485,0,.824-.533.824-1.3A1.012,1.012,0,0,0,4.3,2,.464.464,0,0,0,3.9,1.8Z"
        transform="translate(26.002 9.223)"
      />
      <path
        style={{ fill: '#0c2d5b' }}
        d="M.956,4.054A.909.909,0,0,1,.288,3.76,1.129,1.129,0,0,1,0,2.98a1.4,1.4,0,0,1,.5-1.1,2.078,2.078,0,0,1,1.333-.4,4,4,0,0,1,.45.018.764.764,0,0,0,.037-.259c0-.246-.181-.333-.693-.333a3.9,3.9,0,0,0-.862.111l-.15.055H.562L.712.2a3.872,3.872,0,0,1,1.2-.2A1.565,1.565,0,0,1,2.933.289a1.06,1.06,0,0,1,.347.858c0,.03,0,.059,0,.09a2.981,2.981,0,0,1-.057.669L3.017,3.295l-.038.241-.037.2-.019.13V3.98h-.9l.019-.407A1.176,1.176,0,0,1,.956,4.054Zm.937-1.833c-.568,0-.881.21-.881.592a.364.364,0,0,0,.375.407.6.6,0,0,0,.539-.275A1.4,1.4,0,0,0,2.1,2.24a.664.664,0,0,1-.126-.011A.437.437,0,0,0,1.893,2.221Z"
        transform="translate(22.741 10.037)"
      />
      <path
        style={{ fill: '#0c2d5b' }}
        d="M1.93.925a6.809,6.809,0,0,1,.787.056l.15-.907A9.019,9.019,0,0,0,1.93,0C.787,0,.394.611.394,1.333a1.1,1.1,0,0,0,.768,1.055c.431.2.506.241.506.407,0,.241-.225.389-.637.389a3.38,3.38,0,0,1-.918-.148L0,3.924H.019l.187.037A.881.881,0,0,0,.469,4a4.045,4.045,0,0,0,.6.037c1.143,0,1.63-.426,1.63-1.3a1.083,1.083,0,0,0-.787-1.074c-.431-.2-.487-.222-.487-.407S1.612.925,1.93.925"
        transform="translate(9.548 10.037)"
      />
      <path
        style={{ fill: '#fffffe' }}
        d="M3.467.241,3.3,1.277a1.991,1.991,0,0,0-.918-.259c-.787,0-1.331.741-1.331,1.8,0,.722.375,1.166.974,1.166A1.981,1.981,0,0,0,2.9,3.739L2.717,4.831a3.532,3.532,0,0,1-.918.13C.693,4.961,0,4.183,0,2.906,0,1.185.956,0,2.324,0a3.207,3.207,0,0,1,.469.037l.431.111c.131.056.15.056.244.093"
        transform="translate(20.024 8.834)"
      />
      <path
        style={{ fill: '#fffffe' }}
        d="M2.436.019A.239.239,0,0,0,2.324,0C1.986,0,1.8.167,1.481.629L1.574.037H.618L0,3.943H1.031c.375-2.388.469-2.8.974-2.8H2.08A3.483,3.483,0,0,1,2.474.019H2.436"
        transform="translate(17.738 9.778)"
      />
      <path
        style={{ fill: '#fffffe' }}
        d="M1.574,4.424a2.286,2.286,0,0,1-.75.13A.757.757,0,0,1,0,3.7,2.479,2.479,0,0,1,.038,3.35l.056-.389.056-.3L.6,0H1.63L1.518.574h.525l-.15.963H1.368L1.087,3.147c0,.074-.019.129-.019.167,0,.2.113.3.356.3a1.032,1.032,0,0,0,.281-.037l-.131.852"
        transform="translate(12.622 9.242)"
      />
      <path
        style={{ fill: '#fffffe' }}
        d="M.394,1.314a1.122,1.122,0,0,0,.787,1.073c.431.2.487.259.487.444,0,.241-.187.352-.6.352A2.839,2.839,0,0,1,.15,3.017L0,3.924H.056l.188.037A.874.874,0,0,0,.506,4c.244.018.45.037.581.037,1.087,0,1.593-.407,1.593-1.3a1.07,1.07,0,0,0-.731-1.092c-.431-.185-.487-.241-.487-.407q0-.333.506-.333a6.007,6.007,0,0,1,.75.074l.15-.907A7.978,7.978,0,0,0,1.949,0C.787,0,.394.592.394,1.314"
        transform="translate(9.811 9.741)"
      />
      <path
        style={{ fill: '#fffffe' }}
        d="M1.256,4.831a1.118,1.118,0,0,1-.909-.437A1.878,1.878,0,0,1,0,3.221a2.931,2.931,0,0,1,.5-1.68,1.832,1.832,0,0,1,.537-.517A1.239,1.239,0,0,1,1.687.833a1.16,1.16,0,0,1,.993.537L2.9,0H3.935L3.148,4.794H2.155l.056-.389A1.234,1.234,0,0,1,1.256,4.831ZM1.893,1.8a.686.686,0,0,0-.579.365,1.84,1.84,0,0,0-.227.949,1.018,1.018,0,0,0,.129.567.448.448,0,0,0,.4.192.7.7,0,0,0,.6-.366,1.793,1.793,0,0,0,.23-.949C2.436,2.065,2.243,1.8,1.893,1.8Z"
        transform="translate(28.269 8.927)"
      />
      <path
        style={{ fill: '#fffffe' }}
        d="M1.761,4.072A1.565,1.565,0,0,1,0,2.332,2.722,2.722,0,0,1,.515.659,1.634,1.634,0,0,1,1.818,0,1.368,1.368,0,0,1,2.827.386,1.45,1.45,0,0,1,3.2,1.425a5.266,5.266,0,0,1-.112.963H1.031a.271.271,0,0,0-.019.111c0,.455.309.685.918.685A2.558,2.558,0,0,0,3,2.943l-.169.962A3.485,3.485,0,0,1,1.761,4.072Zm0-3.184c-.318,0-.563.263-.656.7H2.249v-.2A.445.445,0,0,0,1.761.889Z"
        transform="translate(14.533 9.723)"
      />
      <path
        style={{ fill: '#fffffe' }}
        d="M4.142,4.794H3.111l.6-3.684L2.38,4.794H1.668L1.593,1.129.956,4.794H0L.806,0h1.48l.056,2.962L3.336,0H4.947L4.142,4.794"
        transform="translate(2.127 8.927)"
      />
      <path
        style={{ fill: '#fffffe' }}
        d="M.937,4.035A.948.948,0,0,1,0,2.98c0-.953.663-1.5,1.818-1.5A3.4,3.4,0,0,1,2.23,1.5a.621.621,0,0,0,.038-.241C2.268,1,2.1.907,1.612.907a3.03,3.03,0,0,0-.862.111L.6,1.055l-.094.018L.656.2A4.27,4.27,0,0,1,1.893,0,1.577,1.577,0,0,1,2.914.287a1.034,1.034,0,0,1,.347.842,4.072,4.072,0,0,1-.094.777L2.961,3.295l-.037.241-.019.2-.019.13-.019.111H2.005l.019-.407A1.294,1.294,0,0,1,.937,4.035Zm.937-1.814a1.186,1.186,0,0,0-.658.151.5.5,0,0,0-.222.442.371.371,0,0,0,.375.407.648.648,0,0,0,.53-.275,1.229,1.229,0,0,0,.22-.706A1.24,1.24,0,0,0,1.874,2.221Z"
        transform="translate(6.719 9.741)"
      />
      <path
        style={{ fill: '#fffffe' }}
        d="M.956,4.035a.9.9,0,0,1-.689-.292A1.109,1.109,0,0,1,0,2.98a1.37,1.37,0,0,1,.483-1.1,2.12,2.12,0,0,1,1.354-.4,3.4,3.4,0,0,1,.412.018.624.624,0,0,0,.037-.241c0-.26-.172-.352-.656-.352a3.035,3.035,0,0,0-.862.111l-.15.037-.094.018L.675.2A4.268,4.268,0,0,1,1.911,0,1.577,1.577,0,0,1,2.933.287a1.034,1.034,0,0,1,.347.842,4.055,4.055,0,0,1-.094.777L2.98,3.295l-.038.241-.037.2-.019.13V3.98H2.024l.019-.407A1.325,1.325,0,0,1,.956,4.035Zm.937-1.814a1.186,1.186,0,0,0-.658.151.5.5,0,0,0-.223.442.371.371,0,0,0,.375.407c.416,0,.731-.413.75-.981A1.238,1.238,0,0,0,1.893,2.221Z"
        transform="translate(23.041 9.741)"
      />
      <path
        style={{ fill: '#fffffe' }}
        d="M2.436.019A.141.141,0,0,0,2.342,0C2.005,0,1.8.167,1.481.629L1.574.037H.637L0,3.943H1.049c.356-2.388.469-2.8.956-2.8H2.08A3.988,3.988,0,0,1,2.474.019H2.436"
        transform="translate(26.264 9.778)"
      />
    </svg>
  )
}

MasterCardIcon.propTypes = {}

export default MasterCardIcon
