import React from 'react'
import PropTypes from 'prop-types'

import { sanitizeDom } from '../../../utils'
import { useStyles } from './payment-price-change-alert.styles'
import { priceChangeAlertMessage } from '../subscription-constants'

const PaymentPriceChangeAlert = ({ isAnnuallyPlan, isStandardPlan }) => {
  const styles = useStyles()
  let message = ''
  if (isAnnuallyPlan) {
    message = priceChangeAlertMessage.annual
  } else if (isStandardPlan) {
    message = priceChangeAlertMessage.standard
  }

  return (
    <div
      className={styles.priceChangeAlertContainer}
      dangerouslySetInnerHTML={{ __html: sanitizeDom(message) }}
    />
  )
}

PaymentPriceChangeAlert.propTypes = {
  isAnnuallyPlan: PropTypes.bool.isRequired,
  isStandardPlan: PropTypes.bool.isRequired
}

export default PaymentPriceChangeAlert
