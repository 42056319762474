import React from 'react'

const VisaCardIcon = props => {
  return (
    <svg viewBox="0 0 34.95 23.239" {...props}>
      <path
        style={{ fill: '#005186' }}
        d="M34.356,23.239H0V0H34.95V23.239h-.594"
      />
      <path
        style={{ fill: '#fffffe' }}
        d="M0,22.066H33.762V0H0Z"
        transform="translate(0.594 0.587)"
      />
      <path
        style={{ fill: '#005186' }}
        d="M0,7.141,2.193,0H4.149L1.956,7.141H0"
        transform="translate(12.796 8.062)"
      />
      <path
        style={{ fill: '#005186' }}
        d="M6.211,0,4.451,3.027a7.29,7.29,0,0,0-.836,1.694H3.588c.031-.635-.058-1.415-.067-1.856L3.327,0H.034L0,.192A1.36,1.36,0,0,1,1.486,1.471l.642,5.669H4.154L8.253,0H6.211"
        transform="translate(5.711 8.064)"
      />
      <path
        style={{ fill: '#005186' }}
        d="M7.111,7.128H5.121L5.067,6.066l-2.443,0-.5,1.064H0L3.851,0H6.459l.652,7.128ZM4.864,1.238c-.075.224-.3.724-.525,1.207-.128.279-.248.543-.331.736L3.28,4.746H5l-.1-1.83a16.042,16.042,0,0,1,0-1.678Z"
        transform="translate(22.015 8.075)"
      />
      <path
        style={{ fill: '#005186' }}
        d="M2.952,7.5A5.519,5.519,0,0,1,0,6.682L.932,5.276a3.641,3.641,0,0,0,2.11.7A.912.912,0,0,0,3.9,5.569c.272-.465-.063-.714-.827-1.142l-.377-.242C1.561,3.42,1.071,2.693,1.6,1.426A2.71,2.71,0,0,1,4.331,0,4.415,4.415,0,0,1,6.875.865L5.8,2.106a2.4,2.4,0,0,0-1.516-.657.916.916,0,0,0-.835.369c-.2.4.066.671.661,1.036l.449.282c1.376.857,1.7,1.755,1.358,2.6A2.839,2.839,0,0,1,2.952,7.5"
        transform="translate(16.047 7.905)"
      />
      <path
        style={{ fill: '#005186' }}
        d="M.458.921A.444.444,0,0,1,0,.46.449.449,0,0,1,.458,0,.448.448,0,0,1,.914.46.448.448,0,0,1,.458.921Zm0-.841A.358.358,0,0,0,.1.46.354.354,0,0,0,.458.84.357.357,0,0,0,.815.46.361.361,0,0,0,.458.08ZM.366.724H.282V.214h.2c.128,0,.188.047.188.147A.13.13,0,0,1,.531.5L.675.723h-.1L.446.506H.366V.724Zm0-.438V.434H.461C.524.434.58.428.58.354S.521.285.469.285Z"
        transform="translate(29.342 8.306)"
      />
      <path
        style={{ fill: '#eea339' }}
        d="M0,4.234H32.04V0H0Z"
        transform="translate(1.485 17.42)"
      />
      <path
        style={{ fill: '#005186' }}
        d="M0,4.234H32.04V0H0Z"
        transform="translate(1.425 1.642)"
      />
      <path
        style={{ fill: '#005186' }}
        d="M.459.921A.444.444,0,0,1,0,.46.449.449,0,0,1,.459,0,.448.448,0,0,1,.915.46.448.448,0,0,1,.459.921Zm0-.841A.358.358,0,0,0,.1.46.354.354,0,0,0,.459.84.357.357,0,0,0,.815.46.361.361,0,0,0,.459.08ZM.366.724H.282V.214h.2c.128,0,.188.047.188.147A.13.13,0,0,1,.531.5L.675.723h-.1L.446.506H.366V.724Zm0-.438V.435H.461c.063,0,.12-.006.12-.08S.521.286.469.286Z"
        transform="translate(32.367 20.63)"
      />
    </svg>
  )
}

VisaCardIcon.propTypes = {}

export default VisaCardIcon
