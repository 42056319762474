import {
  test,
  complement,
  tryCatch,
  pipe,
  prop,
  path,
  always,
  equals,
  whereEq,
  defaultTo,
  replace,
  split,
  map,
  fromPairs
} from 'ramda'
import {
  compose,
  withHandlers,
  lifecycle,
  mapProps,
  setDisplayName
} from 'recompose'
import qs from 'query-string'

import IFrame from '../../../components/shared/iframe'
import { ACCOUNT_SCREENS } from '../../../constants'

const isNotEV = complement(
  test(/\bevergent.com\b/)
)

const parseData = tryCatch(
  pipe(
    prop('data'),
    JSON.parse
  ),
  always({})
)

/**
 * This was added to prevent an issue caused by `qs.parse`.
 * This util is _basic_ and was not meant to handle more complex qs, like arrays etc,
 * but potentially could later on it we want it to.
 * If a key appears in multiple pairs, the rightmost pair is included in the object.
 * @example getQueryParameters('c=3&a=1&b=2')
 * // {"a": "1", "b": "2", "c": "3"}
 * @example getQueryParameters('foo[]=1&foo[]=2&foo[]=3')
 * // {"foo[]": "3"}
 * @example getQueryParameters('likes=cake&name=bob&likes=icecream')
 * // {"likes": "icecream", "name": "bob"}
 * @example getQueryParameters('color=chartreuse&color=taupe&id=515')
 * // {"color": "taupe", "id": "515"}
 */
export const getQueryParameters = pipe(
  replace(/(^\?)/, ''),
  split('&'),
  map(
    split('=')
  ),
  fromPairs
)

const extractQueryParameters = pipe(
  defaultTo(''),
  qs.extract,
  getQueryParameters
)

// Checks if the message from the iframe indicates the account link was clicked
const accountLinkClicked = whereEq({ type: 'link', page: 'account' })
// Checks if the message from the iframe indicates the cancel button was clicked
const cancelBtnClicked = whereEq({ type: 'link', page: 'cancel' })

const isHeightType = whereEq({ type: 'height' })

const getIframeUrl = tryCatch(
  path(['contentWindow', 'location', 'href']),
  path(['contentWindow', 'location'])
)

/**
 * EV IFrame container that takes the pain out of reuseable EV iframes
 * Maintains a cached reference of iframe ref as well as the element's given height.
 * @prop {string} src - Iframe url
 * @prop {string} className - Optional class name
 *
 * @prop {Function} onLoad - Method that hooks into the iframe load event
 * @param {Object} searchParams - Extracted search params from iframe url
 * @param {Event} event - Event message from hook
 *
 * @prop {Function} onMessage - Method that hooks into EV `message` events
 * @param {Object} data - Parsed JSON data prop from iframe postMessage
 * @param {Event} event - Event message from hook
 *
 * @prop {Function} onCancel - Method that hooks into EV's cancel data `message` event
 * @param {Event} event - Event message from hook
 *
 */
let onLoadRef

const enhance = compose(
  setDisplayName('EvIframe'),
  withHandlers(() => {
    let refCache

    return {
      innerRef: () => (ref) => {
        if (refCache) {
          return refCache
        }
        refCache = ref
        return refCache
      }
    }
  }),
  withHandlers(({ innerRef }) => {
    let heightCache

    return {
      setHeight: () => (height) => {
        if (equals(heightCache, height) || isNaN(height)) return
        heightCache = height + 8
        innerRef().height = heightCache
      }
    }
  }),
  withHandlers({
    onLoad: ({ onLoad }) => (e, iframeUrl) => {
      const queryParams = extractQueryParameters(iframeUrl)
      if (onLoad) {
        onLoad(queryParams, e)
      }
    },
    onMessage: ({
      onMessage,
      onCancel,
      setHeight
    }) => (e) => {
      if (isNotEV(e.origin)) return

      const data = parseData(e)

      if (isHeightType(data)) {
        setHeight(data.height)
      }

      if (onMessage) {
        onMessage(data, e)
      }

      if (accountLinkClicked(data)) {
        window.open(ACCOUNT_SCREENS.SUBSCRIPTION_PAYMENT, '_blank')
      }

      if (onCancel && cancelBtnClicked(data)) {
        onCancel(e)
      }
    },
    // To keep IFrame component clean and backwards compatibility
    handleIframeRef: ({ innerRef }) => (r) => {
      return innerRef(r)
    }
  }),
  lifecycle({
    // https://developer.mozilla.org/en-US/docs/Web/Events
    componentDidMount() {
      const onLoadHandler = this.props.onLoad
      onLoadRef = function onLoad(e) {
        const iframeUrl = getIframeUrl(this) // scope matters here
        onLoadHandler(e, iframeUrl)
      }
      this.props.innerRef().addEventListener('load', onLoadRef)
      window.addEventListener('message', this.props.onMessage, false)
    },
    componentWillUnmount() {
      this.props.innerRef().removeEventListener('load', onLoadRef)
      window.removeEventListener('message', this.props.onMessage)
    }
  }),
  mapProps(({ src, handleIframeRef, className }) => {
    return {
      src,
      handleIframeRef,
      className
    }
  })
)

export default enhance(IFrame)
