import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useStyles } from './plan-details.styles'
/**
 * Renders the specified list of plan details
 * in a card.
 *
 * @param {{ list }} props
 */
const PlanDetails = ({ detail, isUpgradingPlan = false, promotionRejected = false }) => {
  const styles = useStyles()
  // remove filteredNames and filteredValues after MW remove firstPaymentWithoutFreeTrial
  const FIRST_PAYMENT_WITHOUT_FREE_TRIAL = 'firstPaymentWithoutFreeTrial'
  const filterFunc = name => !name.includes(FIRST_PAYMENT_WITHOUT_FREE_TRIAL)
  const featureNames = detail.map(item => item.name)
  const featureValues = detail.map(item => item.value)
  const filteredNames = featureNames.filter(filterFunc)
  const filteredValues = featureValues.filter(filterFunc)

  const isDateValue = (value) => !isNaN(new Date(value).getTime()) && !value.includes('$')
  const featureClasses = (value) => classNames(styles.detailItem, {
    [styles.green]: isDateValue(value)
  })

  return (
    <div className={styles.detailCard}>
      <ul className={classNames(styles.detailList, styles.alignRight)} data-e2e="plan-detail-label">
        {filteredNames.map((name) => (
          <li key={name} className={featureClasses(name)}>
            {name}
          </li>
        ))}
      </ul>

      <ul className={classNames(styles.detailList, styles.alignLeft)} data-e2e="plan-detail-value">
        {filteredValues.map((value) => (
          <li key={value} className={featureClasses(value)}>
            {isDateValue(value) && isUpgradingPlan ? 'Today' : value}
          </li>
        ))}
      </ul>
    </div>
  )
}

export const PlanDetailPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })
).isRequired

PlanDetails.propTypes = {
  detail: PlanDetailPropTypes,
  isUpgradingPlan: PropTypes.bool,
  promotionRejected: PropTypes.bool
}

export default PlanDetails
