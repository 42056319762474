import { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { isEmpty } from 'ramda'

import Yup from '../../../lib/yup'
import { FORM_MESSAGES, FORM_VALUES } from '../../../constants'
import VALIDATE_PASSWORD from '../../../../graphql/queries/validate-password.gql'

const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .required(FORM_MESSAGES.required)
    .min(FORM_VALUES.password.min, FORM_MESSAGES.password.min)
})

const usePassword = (changeSubscription, selectedCardPaymentId) => {
  const [error, setError] = useState('')

  const [password, setPassword] = useState('')
  const [buttonDisabled, setButtonDisabled] = useState(true)

  const [validate, { data, error: validationError, loading }] = useLazyQuery(
    VALIDATE_PASSWORD
  )

  useEffect(() => {
    if (!loading && !validationError && data && selectedCardPaymentId) {
      changeSubscription({
        variables: {
          paymentMethodId: selectedCardPaymentId
        }
      })
    } else if (!loading && validationError) {
      setError("Sorry! We don't recognise this password.")
    }
  }, [data, validationError, loading])

  const onChange = event => {
    const { target } = event
    setPassword(target.value)
    setError('')
    setButtonDisabled(isEmpty(target.value))
  }

  const onBlur = event => {
    const { target } = event

    passwordSchema
      .validate({
        [target.name]: target.value
      })
      .catch(err => {
        setError(err.errors[0])
      })
  }

  const clear = () => {
    setError('')
    setPassword('')
  }

  const onClick = event => {
    event.preventDefault()
    event.stopPropagation()
    validate({
      variables: {
        password
      },
      fetchPolicy: 'network-only'
    })
  }

  return {
    onChange,
    onBlur,
    value: password,
    clear,
    onClick,
    enabled: !buttonDisabled,
    error,
    loading
  }
}

export default usePassword
