import { createUseStyles } from 'react-jss'

/**
 * Defines styles for common elements used within the sign up flow
 *
 * @param {Theme} theme The signup module theme
 */
export const commonStyles = theme => ({
  container: {
    maxWidth: 580,
    margin: [0, 10],

    [theme.media.tablet]: {
      margin: 'auto'
    }
  },

  copy: {
    textAlign: 'center'
  },

  image: {
    width: '100%'
  },

  buttons: {
    marginBottom: 60
  },

  primaryButton: {
    display: 'block',
    fontSize: 16,
    padding: [12, 0],
    textAlign: 'center',
    cursor: 'pointer',
    fontWeight: 800,
    color: theme.black,
    background: theme.neonGreen,
    border: '1px solid  transparent',
    outline: 'none',
    borderRadius: 4,
    transition: 'all 0.25s',
    margin: [20, 0],

    '&:hover': {
      background: theme.neonGreenLight
    }
  },

  secondaryButton: {
    display: 'block',
    fontSize: 16,
    padding: [12, 0],
    textAlign: 'center',
    cursor: 'pointer',
    color: theme.white,
    background: 'transparent',
    border: `1px solid ${theme.white}`,
    outline: 'none',
    borderRadius: 4,
    transition: 'all 0.25s',
    margin: [20, 0],

    '&:hover': {
      color: theme.neonGreen,
      borderColor: theme.neonGreen
    }
  }
})

export const useCommonStyles = createUseStyles(commonStyles)
