import qs from 'query-string'
import { useEffect } from 'react'

import { useNode } from '../../signup/hooks/use-node'

/**
 * Detect add payment method iframe url from Evergent
 *
 */
export const useAddPaymentMethodIframe = (onSuccess, onCancel) => {
  const [iframeRef, iframeNode] = useNode()

  useEffect(() => {
    if (!iframeNode) return () => {}

    const onIframeLoad = () => {
      const returnUrl =
        iframeNode.contentWindow &&
        iframeNode.contentWindow.location &&
        iframeNode.contentWindow.location.href
      const extractedSearchParams = qs.extract(returnUrl)
      const { status } = qs.parse(extractedSearchParams)
      window.removeEventListener('message', onWindowMessage)
      onSuccess(status)
    }

    const onWindowMessage = event => {
      if (event.origin.indexOf('evergent.com') < 0) return

      const data = JSON.parse(event.data)
      const msgType = data && data.type
      const msgPage = data && data.page
      const msgHeight = data && data.height

      if (msgType === 'height' && msgHeight) {
        iframeNode.height = msgHeight
      }

      if (msgType === 'link' && msgPage === 'cancel') {
        onCancel()
      }
    }

    iframeNode.addEventListener('load', onIframeLoad)
    window.addEventListener('message', onWindowMessage)

    return () => {
      if (iframeNode) {
        iframeNode.removeEventListener('load', onIframeLoad)
        window.removeEventListener('message', onWindowMessage)
      }
    }
  }, [iframeNode, onSuccess, onCancel])

  return { iframeRef }
}

export const useAddPaymentMethodIframeWithOrderId = (onSuccess, onCancel) => {
  const [iframeRef, iframeNode] = useNode()

  useEffect(() => {
    if (!iframeNode) return () => {}

    const onIframeLoad = () => {
      const returnUrl =
        iframeNode.contentWindow &&
        iframeNode.contentWindow.location &&
        iframeNode.contentWindow.location.href
      const extractedSearchParams = qs.extract(returnUrl)
      const { status, orderId } = qs.parse(extractedSearchParams)
      window.removeEventListener('message', onWindowMessage)
      onSuccess(status, orderId)
    }

    const onWindowMessage = event => {
      if (event.origin.indexOf('evergent.com') < 0) return

      const data = JSON.parse(event.data)
      const msgType = data && data.type
      const msgPage = data && data.page
      const msgHeight = data && data.height

      if (msgType === 'height' && msgHeight) {
        iframeNode.height = msgHeight
      }

      if (msgType === 'link' && msgPage === 'cancel') {
        onCancel()
      }
    }

    iframeNode.addEventListener('load', onIframeLoad)
    window.addEventListener('message', onWindowMessage)

    return () => {
      if (iframeNode) {
        iframeNode.removeEventListener('load', onIframeLoad)
        window.removeEventListener('message', onWindowMessage)
      }
    }
  }, [iframeNode, onSuccess, onCancel])

  return { iframeRef }
}
