import React from 'react'
import PropTypes from 'prop-types'
import PlanDetails from '../../../signup/components/plan-details'
import { useStyles } from './styles'
import { usePlanChangeFlag } from '../../../../hooks/usePlanChange'
import { usePriceChangeAlertFlag } from '../../../../hooks/usePriceChangeAlert'
import PaymentPriceChangeAlert from '../../../shared/payment-price-change-alert/payment-price-change-alert'

const PaymentMethodHeader = ({
  label,
  copy,
  detail,
  isUpgradingPlan,
  isAnnuallyPlan,
  isStandardPlan
}) => {
  const styles = useStyles()
  const getPlanDetails = usePlanChangeFlag()
  const showPriceChangeAlert = usePriceChangeAlertFlag()

  const legacyPaymentCopy = getPlanDetails?.annual.legacyPaymentCopy
  const upgradeCopy = getPlanDetails?.annual.upgradeCopy

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        Switch to
        {' '}
        {label}
        {' '}
        Plan
      </h1>

      <p className={styles.copy}>{copy}</p>

      <PlanDetails detail={detail} isUpgradingPlan={isUpgradingPlan} />

      {showPriceChangeAlert && <PaymentPriceChangeAlert isStandardPlan={isStandardPlan} isAnnuallyPlan={isAnnuallyPlan} />}

      {isUpgradingPlan && (
        <div className={styles.upgradeCopy}>
          <p className={styles.upgradeInfo}>
            {upgradeCopy}
          </p>
          {isAnnuallyPlan && (
            <p>
              {legacyPaymentCopy}
            </p>
          )}
        </div>
      )}

      <div className={styles.divider} />
    </div>
  )
}

PaymentMethodHeader.propTypes = {
  label: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  detail: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  isUpgradingPlan: PropTypes.bool.isRequired,
  isAnnuallyPlan: PropTypes.bool.isRequired,
  isStandardPlan: PropTypes.bool.isRequired
}

export default PaymentMethodHeader
