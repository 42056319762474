import { createUseStyles } from 'react-jss'
import { commonStyles } from '../shared/common.styles'

const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),

  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.media.desktop]: {
      padding: [40, 160]
    }
  },

  border: {
    border: `1px solid ${theme.white}`,
    borderRadius: '4px',
    padding: 15,
    [theme.media.desktop]: {
      width: 550
    }
  },

  paymentContainer: {
    marginTop: 15
  },

  selector: {
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: 10
  },

  icon: {
    width: 13,
    height: 13,
    marginRight: 10
  },

  iframe: {
    width: '100%',
    minHeight: 'auto',
    border: 'none'
  }
}))

export default useStyles
