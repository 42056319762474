import { EV_SUBSCRIPTION_STATUS } from '../../../constants'

const upgradeAllowedSkus = ['LBPREMTV', 'LBSTDTV']
/**
 * Only allow upgrade if current subscription is LBPREMTV and is active
 */
const isUpgradeAllowed = (
  evSubscriptionStatus,
  evUpcomingSubscriptionStatus,
  sku
) => {
  return (
    evSubscriptionStatus === EV_SUBSCRIPTION_STATUS.ACTIVE &&
    !evUpcomingSubscriptionStatus &&
    sku &&
    upgradeAllowedSkus.indexOf(sku) >= 0
  )
}

export default isUpgradeAllowed
