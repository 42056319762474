import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const IFrame = ({
  handleIframeRef,
  src,
  className,
  onLoad
}) => {
  return (
    <iframe
      onLoad={onLoad}
      ref={handleIframeRef}
      src={src}
      className={classNames(className)}
    />
  )
}

IFrame.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  handleIframeRef: PropTypes.func,
  onLoad: PropTypes.func
}

IFrame.defaultProps = {
  className: '',
  handleIframeRef: () => {},
  onLoad: () => {}
}

export default IFrame
