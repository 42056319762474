import React from 'react'
import PropTypes from 'prop-types'

import { useStyles } from './credit-card-item.styles'
import MasterCardIcon from '../../../../components/icons/master-card-icon'
import VisaCardIcon from '../../../../components/icons/visa-card-icon'
import RadioDotChecked from '../../../../components/icons/radio-dot-checked'
import RadioDot from '../../../../components/icons/radio-dot'

/**
 * A UI component for display single credit card item
 */
const cardType = {
  master: 'MasterCard',
  visa: 'Visa'
}

const CardIcon = ({ type }) => {
  const styles = useStyles()
  return type === cardType.master
    ? <MasterCardIcon className={styles.cardIcon} />
    : <VisaCardIcon className={styles.cardIcon} />
}

CardIcon.propTypes = {
  type: PropTypes.oneOf([cardType.master, cardType.visa]).isRequired
}

const CreditCardItem = ({
  onSelect,
  isSelected,
  card
}) => {
  const styles = useStyles()

  const {
    type,
    id,
    name,
    number
  } = card

  const endNumber = number.substring(number.length - 4, number.length)

  const clickHandler = () => {
    onSelect(id)
  }
  return (
    <div
      onClick={clickHandler}
      className={styles.nameContainer}
    >
      <div className={styles.name}>
        {isSelected
          ? <RadioDotChecked className={styles.icon} />
          : <RadioDot className={styles.icon} />}
        <div>{name}</div>
      </div>
      <div className={styles.card}>
        <CardIcon type={type} />
        <div className={styles.endNumber}>
          <div className={styles.dotGroups}>
            <span className={styles.dot} />
            <span className={styles.dot} />
            <span className={styles.dot} />
            <span className={styles.dot} />
          </div>
          {endNumber}
        </div>
      </div>
    </div>
  )
}

export const CreditCardType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired
}).isRequired

CreditCardItem.propTypes = {
  card: CreditCardType,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func
}

export default CreditCardItem
