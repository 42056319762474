import { graphql } from 'react-apollo'
import { compose, withHandlers } from 'recompose'
import {
  pathOr, reduce, propEq, merge
} from 'ramda'
import omitProps from './omit-props'
import withMutateAndLoading from './with-mutate-and-loading'

import ACCOUNT_CREDIT_CARDS_QUERY from '../../graphql/queries/account-credit-cards.gql'
import SET_CREDITCARD_DEFAULT_MUTATION from '../../graphql/mutations/set-creditcard-default.gql'
import CREDIT_CARD_FRAGMENT from '../../graphql/fragments/credit-cards.gql'

export const withSetCreditcardDefaultMutationAndLoading = compose(
  graphql(SET_CREDITCARD_DEFAULT_MUTATION),
  withMutateAndLoading,
  withHandlers({
    setCreditcardDefault: ({ mutateAndLoading }) => (paymentMethodId, productType) => {
      return mutateAndLoading('setDefaultCreditcard', {
        variables: {
          id: paymentMethodId,
          type: productType
        },
        update: (proxy, result) => {
          const paymentMethodSet = pathOr(false, ['data', 'setCreditcardDefault'], result)
          if (!paymentMethodSet) return

          const accountCache = proxy.readQuery({
            query: ACCOUNT_CREDIT_CARDS_QUERY
          })
          const currentCreditcards = pathOr([], ['account', 'creditcards'], accountCache)

          const productTypeField = {
            TVOD: 'useForPurchases',
            SVOD: 'useForSubscriptions'
          }[productType]

          const iterator = (acc, value) => {
            const matchesType = propEq(productTypeField, true, value)
            const matchesId = propEq('id', paymentMethodId, value)
            if (matchesType) {
              return acc.concat(merge(value, { [productTypeField]: false }))
            } if (matchesId) {
              return acc.concat(merge(value, { [productTypeField]: true }))
            }
            return acc.concat(value)
          }

          const updatedCreditcards = reduce(iterator, [], currentCreditcards)

          updatedCreditcards.forEach((creditcard) => {
            proxy.writeFragment({
              id: `Creditcard:${creditcard.id}`,
              fragment: CREDIT_CARD_FRAGMENT,
              data: creditcard
            })
          })
        }
      })
    }
  }),
  omitProps(['mutate', 'mutateAndLoading'])
)

const withSetCreditcardDefaultMutation = compose(
  graphql(SET_CREDITCARD_DEFAULT_MUTATION),
  withHandlers({
    setCreditcardDefault: ({ mutate }) => (paymentMethodId, productType) => {
      return mutate({
        variables: {
          id: paymentMethodId,
          type: productType
        },
        update: (proxy, result) => {
          const paymentMethodSet = pathOr(false, ['data', 'setCreditcardDefault'], result)
          if (!paymentMethodSet) return

          const accountCache = proxy.readQuery({
            query: ACCOUNT_CREDIT_CARDS_QUERY
          })
          const currentCreditcards = pathOr([], ['account', 'creditcards'], accountCache)

          const productTypeField = {
            TVOD: 'useForPurchases',
            SVOD: 'useForSubscriptions'
          }[productType]

          const iterator = (acc, value) => {
            const matchesType = propEq(productTypeField, true, value)
            const matchesId = propEq('id', paymentMethodId, value)
            if (matchesType) {
              return acc.concat(merge(value, { [productTypeField]: false }))
            } if (matchesId) {
              return acc.concat(merge(value, { [productTypeField]: true }))
            }
            return acc.concat(value)
          }

          const updatedCreditcards = reduce(iterator, [], currentCreditcards)

          updatedCreditcards.forEach((creditcard) => {
            proxy.writeFragment({
              id: `Creditcard:${creditcard.id}`,
              fragment: CREDIT_CARD_FRAGMENT,
              data: creditcard
            })
          })
        }
      })
    }
  }),
  omitProps(['mutate'])
)

export default withSetCreditcardDefaultMutation
